/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'babel-polyfill'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import '../styles/SecFilings.scss'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap'
import '../utils/react-date-picker/styles.scss'
import '../utils/react-date-picker/default.scss'
import { DateRange } from 'react-date-range'
import { getPlatformRequest } from '../api'
import { calDateFormatter, dateFormatForSecFiling, createSecFilingLink } from '../utils/utilities'
import exchangeMap from '../utils/exchangeMap'

export default function SECFilings (props) {
  SECFilings.propTypes = {
    token: PropTypes.string,
    symbol: PropTypes.string,
    exchange: PropTypes.string
  }

  const secFilings = new BEMHelper({
    name: 'secFilings'
  })

  const datePickerRef = useRef(null)
  const [secFilingResponse, setSecFilingResponse] = useState([])
  const [toDate, setToDate] = useState(new Date().toLocaleDateString('en-AU'))
  const [fromDate, setFromDate] = useState(() => {
    let currDate = new Date()
    return new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 1,
      currDate.getDate()
    ).toLocaleDateString('en-AU')
  })
  const [lastUsedValues, setLastUsedValues] = useState({})
  const lastUsedValuesRef = useRef(lastUsedValues)
  const [displayDatePicker, setDisplayDatePicker] = useState(false)
  const [dropDownTitle, setDropDownTitle] = useState('1 Month')
  const [callApi, setCallApi] = useState(false)
  const [cik, setCik] = useState('')

  const getCIK = async () => {
    try {
      const res = await getPlatformRequest(
        'xref',
        `symbols/${props.symbol}?isCaseSensitive=false${
          props.exchange && props.exchange !== 'null'
            ? '&exchangeId=' + exchangeMap[props.exchange.toUpperCase()]
            : ''
        }`
      )
      const cik =
        res.data.data.supplementalData.findIndex(
          (supplementalData) => supplementalData.name === 'cik'
        ) !== -1
          ? res.data.data.supplementalData.find(
              (supplementalData) => supplementalData.name === 'cik'
            ).value
          : ''
      setCik(cik)
    } catch (err) {
      console.log(err)
    }
  }

  const getDocumentList = async () => {
    try {
      if (cik && fromDate && toDate) {
        isCustomDateSelected() &&
          setDropDownTitle(
            `${calDateFormatter(
              fromDate.split('/').reverse().join('-')
            )} - ${calDateFormatter(toDate.split('/').reverse().join('-'))}`
          )
        lastUsedValuesRef.current['fromDate'] = fromDate
        lastUsedValuesRef.current['toDate'] = toDate
        lastUsedValuesRef.current['dropDownTitle'] = dropDownTitle
        setLastUsedValues({ fromDate, toDate, dropDownTitle })
        const res = await getPlatformRequest(
          'sec filing',
          `documentList/${cik}?startDate=${dateFormatForSecFiling(
            fromDate
          )}&endDate=${dateFormatForSecFiling(toDate)}`
        )
        const documentListData = res.data.data.items
        setSecFilingResponse(documentListData)
      }
    } catch (err) {
      setSecFilingResponse([])
      console.log(err)
    }
  }

  const handleDateChange = (e) => {
    if (!e) return
    if (e.selection) {
      let sameDates = e.selection.endDate === e.selection.startDate
      let startDate = new Date(e.selection.startDate).toLocaleDateString(
        'en-AU'
      )
      let endDate = sameDates
        ? toDate
        : new Date(e.selection.endDate).toLocaleDateString('en-AU')
      setFromDate(startDate)
      !sameDates && setToDate(endDate)
    } else {
      switch (e) {
        case '0':
          setFromDate(() => {
            let currDate = new Date()
            return new Date(
              currDate.getFullYear(),
              currDate.getMonth() - 1,
              currDate.getDate()
            ).toLocaleDateString('en-AU')
          })
          setDropDownTitle('1 Month')
          setCallApi(!callApi)
          break
        case '1':
          setFromDate(() => {
            let currDate = new Date()
            return new Date(
              currDate.getFullYear(),
              currDate.getMonth() - 3,
              currDate.getDate()
            ).toLocaleDateString('en-AU')
          })
          setDropDownTitle('3 Month')
          setCallApi(!callApi)
          break
        case '2':
          setFromDate(() => {
            let currDate = new Date()
            return new Date(
              currDate.getFullYear() - 1,
              currDate.getMonth(),
              currDate.getDate()
            ).toLocaleDateString('en-AU')
          })
          setDropDownTitle('1 Year')
          setCallApi(!callApi)
          break
        case '3':
          setFromDate(() => {
            let currDate = new Date()
            return new Date(
              currDate.getFullYear() - 2,
              currDate.getMonth(),
              currDate.getDate()
            ).toLocaleDateString('en-AU')
          })
          setDropDownTitle('2 Year')
          setCallApi(!callApi)
          break
        default:
          break
      }
      setToDate(() => {
        let currDate = new Date()
        return new Date(
          currDate.getFullYear(),
          currDate.getMonth(),
          currDate.getDate()
        ).toLocaleDateString('en-AU')
      })
    }
  }

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      event.composedPath()[0].text !== 'Custom' &&
      !event.composedPath().some(x => x.className === 'datePickerWrapper')
    ) {
      setDisplayDatePicker(false)
      if (lastUsedValuesRef.current) {
        let oldDropDownTitle = lastUsedValuesRef.current.dropDownTitle
        let oldFromDate = lastUsedValuesRef.current.fromDate
        let oldToDate = lastUsedValuesRef.current.toDate
        oldFromDate !== fromDate && setFromDate(oldFromDate)
        oldToDate !== toDate && setToDate(oldToDate)
        setDropDownTitle(
          oldDropDownTitle === 'Custom'
            ? `${calDateFormatter(
                oldFromDate.split('/').reverse().join('-')
              )} - ${calDateFormatter(
                oldToDate.split('/').reverse().join('-')
              )}`
            : oldDropDownTitle
        )
      }
    }
  }

  const isCustomDateSelected = () => {
    return (
      dropDownTitle !== '1 Month' &&
      dropDownTitle !== '3 Month' &&
      dropDownTitle !== '1 Year' &&
      dropDownTitle !== '2 Year'
    )
  }

  useEffect(() => {
    if (!cik) {
      getCIK()
    } else {
      getDocumentList()
    }
  }, [callApi, cik])

  // only for event handlers
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  return (
    <div {...secFilings()}>
      <div>
        <header {...secFilings('header')}>
          <h1 {...secFilings('header', 'headingLeft')}>SEC Filings</h1>
          <DropdownButton
            data-testid='dropDownBtn'
            bsPrefix='dateDropdown'
            title={dropDownTitle}
            menuAlign='right'
            onSelect={(e) => handleDateChange(e)}
          >
            <Dropdown.Item
              bsPrefix='dditem'
              eventKey='0'
              active={dropDownTitle === '1 Month'}
            >
              1 Month
            </Dropdown.Item>
            <Dropdown.Item
              bsPrefix='dditem'
              eventKey='1'
              active={dropDownTitle === '3 Month'}
            >
              3 Month
            </Dropdown.Item>
            <Dropdown.Item
              bsPrefix='dditem'
              eventKey='2'
              active={dropDownTitle === '1 Year'}
            >
              1 Year
            </Dropdown.Item>
            <Dropdown.Item
              bsPrefix='dditem'
              eventKey='3'
              active={dropDownTitle === '2 Year'}
            >
              2 Year
            </Dropdown.Item>
            <Dropdown.Item
              bsPrefix='dditem'
              active={isCustomDateSelected()}
              onClick={(e) => {
                setDisplayDatePicker(true)
                setDropDownTitle('Custom')
              }}
            >
              Custom
            </Dropdown.Item>
          </DropdownButton>
          {displayDatePicker && (
            <div
              className='datePickerWrapper'
              ref={datePickerRef}
            >
              <DateRange
                editableDateInputs
                onChange={(item) => handleDateChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={[
                  {
                    startDate: new Date(
                      fromDate.split('/').reverse().join('/')
                    ),
                    endDate: new Date(toDate.split('/').reverse().join('/')),
                    key: 'selection'
                  }
                ]}
              />
              <button
                className='searchBtn'
                onClick={() => {
                  setCallApi(!callApi)
                  setDisplayDatePicker(false)
                }}
              >
                Search
              </button>
            </div>
          )}
        </header>
        <section>
          <table {...secFilings('table')}>
            <thead>
              <tr {...secFilings('table', 'row')}>
                <th>ACCEPTANCE DATE</th>
                <th>ACCEPTANCE TIME</th>
                <th className='thDesc'>DESCRIPTION</th>
                <th>FORMAT</th>
              </tr>
            </thead>
            <tbody>
              {secFilingResponse && secFilingResponse.length > 0 ? (
                secFilingResponse.map((row) => {
                  return (
                    <tr {...secFilings('table', 'row')}>
                      <td
                        {...secFilings('table', 'item', 'dateTime', 'dateOnly')}
                      >
                        {row.acceptanceDate
                          ? new Date(row.acceptanceDate).toLocaleDateString(
                              'en-AU'
                            )
                          : '--'}
                      </td>
                      <td
                        {...secFilings('table', 'item', 'dateTime', 'timeOnly')}
                      >
                        {row.acceptanceDate
                          ? new Date(row.acceptanceDate).toLocaleTimeString(
                              'en-AU',
                              { hour: '2-digit', minute: '2-digit' }
                            )
                          : '--'}{' '}
                        American Eastern Time
                      </td>
                      <td {...secFilings('table', 'item', 'formTypeDesc')}>
                        {row.formType ? row.formType : '--'} |{' '}
                        {row.formTypeDesc ? row.formTypeDesc : '--'}
                      </td>
                      <td {...secFilings('table', 'item', 'docType')}>
                        {row.allFormats &&
                          (() => {
                            return row.allFormats.map((docItem, index) => {
                              let docKey = btoa(
                                `${row.feedId}-${row.documentTag}-${row.versionTag}`
                              )
                              return (
                                <a
                                  key={docKey + docItem}
                                  target='_blank'
                                  href={`${createSecFilingLink(row.documentTag, row.feedId, row.versionTag, docItem, props.token)}`}
                                >
                                  {docItem}
                                  {index !== row.allFormats.length - 1 && '|'}
                                </a>
                              )
                            })
                          })()}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <h3 {...secFilings('table', 'noResult')}>No SEC Filings available within this timeframe</h3>
              )}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  )
}
