import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import PropTypes from 'prop-types'

const CustomToggle = React.forwardRef(({ children, onClick, customClassName }, ref) => {
  CustomToggle.propTypes = {
    children: PropTypes.element,
    onClick: PropTypes.func,
    customClassName: PropTypes.string
  }

  return (
    <button
      ref={ref}
      onClick={(e) => {
      e.preventDefault()
        onClick(e)
      }}
      className={customClassName}
    >
      {children}
    </button>
  )
})

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    CustomMenu.propTypes = {
      children: PropTypes.array,
      style: PropTypes.object,
      className: PropTypes.string,
      'aria-labelledby': PropTypes.string
    }

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className='list-unstyled'>
          {React.Children.toArray(children).filter(
            (child) =>
              child
          )}
        </ul>
      </div>
    )
  }
)

const Menu = ({children, btnClassName, options, onItemClick, onMenuItemMouseEnter, onMenuItemMouseLeave, onClose}) => {
  Menu.propTypes = {
    children: PropTypes.element,
    btnClassName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
      value: PropTypes.any
    })),
    onItemClick: PropTypes.func,
    onMenuItemMouseEnter: PropTypes.func,
    onMenuItemMouseLeave: PropTypes.func,
    onClose: PropTypes.func
  }

  const handleMouseEnter = (e, item) => {
    if (onMenuItemMouseEnter) {
      onMenuItemMouseEnter(e, item)
    }
  }

  const handleOnMouseLeave = (e, item) => {
    if (onMenuItemMouseLeave) {
      onMenuItemMouseLeave(e, item)
    }
  }

  const onToggle = (isOpen) => {
    if (!isOpen && onClose) {
      onClose()
    }
  }

  return (
    <Dropdown onSelect={onItemClick} onToggle={onToggle}>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' customClassName={btnClassName}>
        {children}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {
          options && options.map((option, index) => (
            <Dropdown.Item as='li' data-index={index} onMouseEnter={(e) => handleMouseEnter(e, option)} onMouseLeave={(e) => handleOnMouseLeave(e, option)} key={option.value} active={false} eventKey={option.value}>{option.label}</Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Menu
