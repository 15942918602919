import React, { useState} from 'react'
import PropTypes from 'prop-types'
import BlueDropIcon from '../../../icons/indicator-other.svg'
import SensitiveIcon from '../../../icons/indicator-market.svg'
import { EVENT_TYPES } from '../consts'
import EventTooltip from './EventTooltip.js'

const EventsOverlay = ({ eventsData, token }) => {
  EventsOverlay.propTypes = {
    eventsData: PropTypes.object,
    token: PropTypes.string
  }

  const [tooltipData, setTooltipData] = useState({ show: false })

  const handleOnMouseEnter = (e, eventData) => {
    e.preventDefault()
    e.stopPropagation()
    const chartWidth = e.currentTarget.offsetParent ? e.currentTarget.offsetParent.offsetWidth : 0
    setTooltipData({
      ...tooltipData,
      eventData: eventData,
      totalWidth: chartWidth,
      show: true
    })
  }

  const handleOnMouseLeave = (e) => {
    setTooltipData({
      ...tooltipData,
      show: false
    })
  }

  const renderEventIcon = (eventType) => {
    const events = eventsData[eventType]
    return events.map((event, index) => {
      const x = event.x - 10
      const y = event.y - 23
      const style = {
        left: x,
        top: y,
        position: 'absolute'
      }

      if (eventType !== EVENT_TYPES.SEC_FILINGS && eventType !== EVENT_TYPES.ANNOUNCEMENTS) {
        style.top = style.top + 5
        style.left = style.left + 2
      }

      if (style.top < 0) {
        style.top = 0 // To stop painting event outside the chart
      }

      let type
      let additionalClasses = []
      switch (eventType) {
        case EVENT_TYPES.SEC_FILINGS:
          type = <img src={BlueDropIcon} alt='mySvgImage' />
          additionalClasses.push('sec-filing')
          break
        case EVENT_TYPES.ANNOUNCEMENTS:
          if (event.event.customData[0].marketsensitive) {
            type = <img src={SensitiveIcon} alt='mySvgImage' />
          } else {
            type = <img src={BlueDropIcon} alt='mySvgImage' />
          }
          additionalClasses.push('announcement')
          break
        case EVENT_TYPES.DIVIDENDS:
          additionalClasses.push('dividend')
          type = 'D'
          break
        case EVENT_TYPES.SPLITS:
          additionalClasses.push('split')
          type = 'S'
          break
        default:
          type = 'E'
          break
      }
      const elementKey = `${eventType}-${index}`

      const eventData = {
        x: style.left,
        y: style.top,
        elementKey,
        eventType,
        event
      }
      return (
        <div
          style={{ ...style }}
          key={`event-${elementKey}`}
          data-eventtype={eventType}
          data-elementkey={elementKey}
          className={`event-icon ${additionalClasses.join(' ')}`}
          onMouseEnter={(e) => handleOnMouseEnter(e, eventData)}
          onMouseLeave={handleOnMouseLeave}
        >
          {type}
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      <div className='events-container'>
        {
          Object.keys(eventsData).map(eventType => {
            return renderEventIcon(eventType)
          })
        }
      </div>
      <EventTooltip eventData={tooltipData.eventData} show={tooltipData.show} token={token} totalWidth={tooltipData.totalWidth} />
    </React.Fragment>
  )
}

export default EventsOverlay
