import React from 'react'
import PropTypes from 'prop-types'
import Menu from './Menu.js'
import { frequencyMenuOptions, getValidFreq } from '../consts'

const Frequency = ({chart, handleFrequency, currentDays, selectedFrequency}) => {
  Frequency.propTypes = {
    chart: PropTypes.object,
    handleFrequency: PropTypes.func,
    currentDays: PropTypes.number,
    selectedFrequency: PropTypes.object
  }

  let days = currentDays
  const isZoomed = chart ? !!chart.params.dateStart : false
  if (isZoomed) {
    days = 21
  }

  const freqToShow = frequencyMenuOptions.filter(row => getValidFreq(days, row)).map(row => ({
    label: row.labelId,
    value: row.id
  }))

  const onfrequencyChange = (id) => {
    const freqSelected = frequencyMenuOptions.find(option => option.id === id)
    handleFrequency(freqSelected)
  }

  return (
    <Menu btnClassName='button' options={freqToShow} onItemClick={onfrequencyChange}>
      <div className='header'>
        <div className='icon--frequency' />
        {selectedFrequency.labelId}
      </div>
    </Menu>
  )
}

export default Frequency
