/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formatDateAsDMY, createAnnouncementLink, announcementDateFormat, secFilingDateFormat } from '../helper'
import { EVENT_TYPES } from '../consts'
import { createSecFilingLink } from '../../../utils/utilities'

const EventTooltip = ({ eventData, token, totalWidth, show = false }) => {
  EventTooltip.propTypes = {
    eventData: PropTypes.object,
    token: PropTypes.string,
    totalWidth: PropTypes.number,
    show: PropTypes.bool
  }

  const [showToolTip, setShowToolTip] = useState(false)

  const style = {
    left: eventData ? eventData.x : 0,
    top: eventData ? eventData.y : 0,
    visibility: (show || showToolTip) ? 'visible' : 'hidden'
  }

  let boxWidth = 140 + 10
  if (eventData && eventData.eventType && eventData.eventType === EVENT_TYPES.SEC_FILINGS) {
    boxWidth = 276 + 10
  }
  if (eventData && eventData.eventType && eventData.eventType === EVENT_TYPES.ANNOUNCEMENTS) {
    boxWidth = 175 + 10
  }
  let arrowClass = ''
  const width = totalWidth ? totalWidth / 2 : boxWidth

  if (style.left < width) {
    // no space in left, so open box on right
    style.left = style.left + 27
    arrowClass = 'arrow-left'
  } else {
    // space available on left, open box on left
    style.left = style.left - boxWidth // width of content
    arrowClass = 'arrow-right'
  }

  style.top = style.top - 10

  const secFilingContent = (secFilings) => {
    const content = (
      <div className='section-wrapper'>
        {
          secFilings && secFilings.map(doc => (
            <div className='section' key={doc.documentTag}>
              <div className='heading'>
                <a
                  target='_blank'
                  href={`${createSecFilingLink(doc.documentTag, doc.feedId, doc.versionTag, 'PDF', token)}`}
                >
                  {
                    doc.formType
                  }
                  {
                    doc.formType && doc.formTypeDesc && ' | '
                  }
                  {
                    doc.formTypeDesc
                  }
                </a>
              </div>
              <div className='formats'>
                {
                  doc.allFormats && (() => {
                    return doc.allFormats.filter(format => format !== 'PDF').map((docItem, index) => {
                      let docKey = btoa(
                        `${doc.feedId}-${doc.documentTag}-${doc.versionTag}`
                      )
                      return (
                        <a
                          key={docKey + docItem}
                          className='formats__type'
                          target='_blank'
                          href={`${createSecFilingLink(doc.documentTag, doc.feedId, doc.versionTag, docItem, token)}`}
                        >
                          {docItem === 'HTM' ? 'HTML' : docItem}
                        </a>
                      )
                    })
                  })()
                }
              </div>
              <div className='acceptance-date'>
                {doc.acceptanceDate && secFilingDateFormat(doc.acceptanceDate)}
              </div>
            </div>
          ))
        }
      </div>
    )
    return content
  }

  const announcementContent = (announcements) => {
    const content = (
      <div className='section-wrapper'>
        {
          announcements && announcements.map(announcement => (
            <div className='section' key={announcement.documentKey}>
              <a className='doc-link' target='_blank' href={`${createAnnouncementLink(announcement.documentKey, token)}`}>{announcement.headline}</a>
              <small className='timestamp'>{announcementDateFormat(announcement.documentDate)}</small>
            </div>
          ))
        }
      </div>
    )
    return content
  }

  const dividendContent = (dividends, eventCurrency) => {
    let prefix = ''
    let suffix = ''
    const value = Number(dividends || 0).toFixed(2)
    const currency = eventCurrency || ''
    if (/USD|AUD/.test(currency)) {
      prefix = '$'
    } else if (currency === 'GBP') {
      prefix = '&pound;'
    } else if (currency.length) {
      suffix = ` (${currency})`
    }
    const content = (
      <div className='value'>
        {`${prefix} ${value} ${suffix}`}
      </div>
    )
    return content
  }

  const popoverContent = () => {
    let content = null
    let additionalClasses = []
    // const elementKey = eventData.elementKey
    const eventType = eventData.eventType
    const event = eventData.event
    switch (eventType) {
      case EVENT_TYPES.SEC_FILINGS:
        additionalClasses.push('sec-filing-popup')
        content = secFilingContent(event.event.customData)
        break
      case EVENT_TYPES.ANNOUNCEMENTS:
        additionalClasses.push('announcement-popup')
        content = announcementContent(event.event.customData)
        break
      case EVENT_TYPES.DIVIDENDS:
        content = dividendContent(event.event.dividends, event.event.currency)
        break
      case EVENT_TYPES.SPLITS:
        content = (
          <div className='value'>
            {event.event.splits}
          </div>
        )
        break
      default:
        break
    }

    return (
      <div className={`event-popup-container ${additionalClasses.join(' ')}`}>
        <div className='date'>
          {
            formatDateAsDMY(new Date(event.event.date))
          }
        </div>
        <div className='content'>
          {content}
        </div>
      </div>
    )
  }

  const handleOnMouseEnter = () => {
    setShowToolTip(true)
  }

  const handleOnMouseLeave = () => {
    setShowToolTip(false)
  }

  return (
    <div className={`event-tooltip ${arrowClass}`} style={{ ...style }} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {
        (show || showToolTip) && eventData && popoverContent()
      }
    </div>
  )
}

export default EventTooltip
