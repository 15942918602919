import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SECFilings from './components/SECFilings'
import PriceChart from './components/PriceChart'
import CompanyPerformance from './components/CompanyPerformance'
import CompanyPopover from './components/CompanyPopover'

import './App.scss'
import PricePerformanceChart from './components/PricePerformanceChart/PricePerformanceChart'

function App () {
  let queryParam = new URLSearchParams(window.location.search)
  let token = queryParam.get('token')
  let symbol = queryParam.get('symbol')
  let exchange = queryParam.get('exchange')
  let isrealtime = queryParam.get('isrealtime')
  let venuexid = queryParam.get('venuexid')
  let height = queryParam.get('height')
  let width = queryParam.get('width')
  let wrapped = queryParam.get('wrapped')

  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route excat path='/chart' render={() => <PriceChart token={token} symbol={symbol} isWebComponent={false} exchange={exchange} />} />
          <Route excat path='/priceperformancechart' render={() => <PricePerformanceChart token={token} venuexid={venuexid} height={height} width={width} />} />
          <Route excat path='/secfilings' render={() => <SECFilings token={token} symbol={symbol} exchange={exchange} />} />
          <Route excat path='/companyPerformance' render={() => <CompanyPerformance token={token} width={width} isrealtime={isrealtime} />} />
          <Route excat path='/companyPopover' render={() => <CompanyPopover token={token} symbol={symbol} exchange={exchange} wrapped={wrapped} />} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
