import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'react-bootstrap/Dropdown'

import { EVENT_TYPES, exchanges } from '../consts'

const eventDisplayName = (event) => {
  let name = event
  switch (event) {
    case EVENT_TYPES.DIVIDENDS:
      name = 'Dividends'
      break
      case EVENT_TYPES.SPLITS:
        name = 'Splits'
      break
      case EVENT_TYPES.ANNOUNCEMENTS:
        name = 'Announcements'
      break
      case EVENT_TYPES.SEC_FILINGS:
        name = 'Sec Filings'
      break
  }
  return name
}

const EventMenu = ({exchangeID, selectedEvents, onEventToggle}) => {
  EventMenu.propTypes = {
    exchangeID: PropTypes.number,
    selectedEvents: PropTypes.object,
    onEventToggle: PropTypes.func
  }

  let menuToShow = [EVENT_TYPES.DIVIDENDS, EVENT_TYPES.SPLITS, EVENT_TYPES.ANNOUNCEMENTS]

  if (exchangeID === exchanges.NSQ || exchangeID === exchanges.NYQ) {
    menuToShow = [EVENT_TYPES.DIVIDENDS, EVENT_TYPES.SPLITS, EVENT_TYPES.SEC_FILINGS]
  }

  const handlePanelEvent = (e, eventToToggle) => {
    onEventToggle(eventToToggle)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle bsPrefix='eventDropdown' variant='link'>
        <div className='eventDropdown__wrapper'>
          <div className='plus' />
          <div><span className='txt'>Event</span></div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          menuToShow.map((event, index) => (
            <Dropdown.Item
              key={event}
              href='#'
              bsPrefix='event-item'
              active={selectedEvents[event]}
              eventKey={`${index}`}
              onClick={(e) => handlePanelEvent(e, event)}
            >
              {eventDisplayName(event)}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default EventMenu
