/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { ModchartsInteractive, locales } from '@markit/modcharts'
import { getPlatformRequest, getComsecApiRequest } from '../../api'
import Markertype from './components/Markertype.js'
import TimeFrame from './components/TimeFrame.js'
import Frequency from './components/Frequency.js'
import EventsOverlay from './components/EventsOverlay.js'
import EventMenu from './components/EventMenu.js'
import { frequencyMenuOptions, exchanges, EVENT_TYPES, timeframeMenuOptions } from './consts'
import { transeformData, formatDateAsYMD, formatDateAsDMY, getFrequencyByDays } from './helper'
import '../../styles/PriceChart.scss'

const getStartDate = (days) => {
  let currDate = new Date()
  return new Date(
    currDate.getFullYear(),
    currDate.getMonth(),
    currDate.getDate() - days
  )
}

const getToDate = () => {
  return new Date()
}

const secFilingDataLimit = 9999

const getCik = (xrefInfo) => {
  const cik =
      xrefInfo.supplementalData.findIndex(
        (supplementalData) => supplementalData.name === 'cik'
      ) !== -1
        ? xrefInfo.supplementalData.find(
            (supplementalData) => supplementalData.name === 'cik'
          ).value
        : ''
  return cik
}

const PriceChart = ({ token, symbol, exchange, isWebComponent = true }) => {
  const priceChart = new BEMHelper({
    name: 'price-chart'
  })
  const exchangeID = exchange && exchanges[exchange] ? exchanges[exchange] : exchanges['NSQ']
  const isUSSymbol = exchangeID === exchanges.NSQ || exchangeID === exchanges.NYQ
  const [chart, setChart] = useState(null)
  const [xrefInfo, setXrefInfo] = useState(null)
  const [secFilingData, setSecFilingData] = useState(null)
  const [announcementData, setAnnouncementData] = useState(null)
  const [selectedEvents, setSelectedEvents] = useState({
    [EVENT_TYPES.EARNINGS]: false,
    [EVENT_TYPES.DIVIDENDS]: false,
    [EVENT_TYPES.SPLITS]: false,
    [EVENT_TYPES.ANNOUNCEMENTS]: !isUSSymbol,
    [EVENT_TYPES.SEC_FILINGS]: isUSSymbol
  })
  const [eventsData, setEventsData] = useState({})
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    timeframeMenuOptions[0]
  )
  const [selectedFrequency, setSelectedFrequency] = useState(
    frequencyMenuOptions[5]
  )
  const counterSetValue = useState(0) // integer state

  const forceRerender = () => {
    counterSetValue[1](value => value + 1) // update state to force render
  }

  const getXrefInfo = async () => {
    try {
      const res = await getPlatformRequest('xref', `symbols/${symbol}?exchangeId=${exchangeID}`)
      setXrefInfo(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const initNewChart = useCallback((xid) => {
    const newChart = window.chart = new ModchartsInteractive()
    const element = isWebComponent ? document.querySelector('price-chart-webcomponent').shadowRoot.getElementById('chart-dom-element') : '#chart-dom-element'

    newChart.mount(element)
    newChart.setAuthToken(token)

    const myLocale = locales.en_AU

    myLocale.labelTemplates.xAxis.doubleRow = {
      ...myLocale.labelTemplates.xAxis.doubleRow,
      days: {
        'default': '%e',
        'first': '%B %Y',
        'firstAlt': '%x %A',
        'intraday': '%x %A'
      },
      months: {
        'default': '%B',
        'first': '%Y',
        'firstAlt': '%B %Y'
      },
      weeks: {
        'default': '%-d/%-m',
        'first': '%B %Y',
        'firstAlt': '%B %Y'
      }
    }

    newChart.load({
      params: {
        apiPath: window.MD.chartAPIPath,
        symbol: xid,
        days: selectedTimeFrame.days,
        locale: myLocale,
        dataPeriod: 'Day',
        dataInterval: 1,
        showFlags: true,
        xAxisShortMonths: false,
        crosshair: {
          enabled: true,
          flagEnabled: true
        },
        style: {
          panel: {
            grid: {
              horizontal: {
                lineDash: '1,0'
              },
              vertical: {
                lineDash: '1,0',
                alt: { color: 'transparent' }
              }
            }
          }
        }
      },
      panels: [{
        indicators: [{
          id: 'price',
          markerType: 'fill',
          style: {
            color: '#0073b1',
            width: '1',
            background: {
              color: 'rgba(95, 179, 241, 0.1)',
              colorStop: 'rgba(95, 179, 241, 0.1)'
            }
          }
        }]
      }]
    })
    setChart(newChart)
  }, [])

  useEffect(() => {
    if (!xrefInfo) {
      getXrefInfo()
    } else if (xrefInfo && !chart) {
      initNewChart(xrefInfo.xids.venue)
    }
  }, [xrefInfo, chart, initNewChart])

  useEffect(() => {
    if (!(chart && chart.panels && chart.panels.length)) return
    registerChartEvents()
    return () => unregisterChartEvents()
  }, [chart])

  const handleFrequency = (freqSelected, selectedDays) => {
    const period = freqSelected.period
    const interval = freqSelected.interval
    chart.setDataInterval(interval)
    chart.setDataPeriod(period)

    if (freqSelected.intraday) {
      selectedDays = 21
    }

    if (selectedDays) {
      chart.setDays(selectedDays)

      const timeFrame = timeframeMenuOptions.find(option => option.days === selectedDays)
      if (timeFrame) {
        setSelectedTimeFrame(timeFrame)
      }
    }
    setSelectedFrequency(freqSelected)
    chart.loadData()
  }

  const onTimeFrameSelected = (timeFrameSelected) => {
    if (!chart) return

    const freq = getFrequencyByDays(timeFrameSelected.days)
    chart.setDays(timeFrameSelected.days)
    chart.setDataInterval(freq.dataInterval)
    chart.setDataPeriod(freq.dataPeriod)
    chart.loadData()
    const frequency = frequencyMenuOptions.find(option => option.period === freq.dataPeriod)
    if (frequency) {
      setSelectedFrequency(frequency)
    }
    setSelectedTimeFrame(timeFrameSelected)
  }

  const fetchAnnouncementsData = async (symbol, exchangeCode, startDate, days) => {
    const params = {
      params: {
        access_token: token,
        symbol: symbol,
        exchange: exchangeCode,
        days: days,
        startRow: 0,
        numRows: 9999,
        dateStart: startDate
      }
    }

    const res = await getComsecApiRequest(`events/announcements`, params)

    if (res.data.data.announcements.length) {
      const announcements = res.data.data.announcements.map((item, index) => ({
        date: formatDateAsYMD(new Date(item.documentDate)),
        ...item
      }))

      const sensitiveDataSet = transeformData(EVENT_TYPES.ANNOUNCEMENTS, announcements.filter(({marketsensitive}) => marketsensitive))
      const marketDataSet = transeformData(EVENT_TYPES.ANNOUNCEMENTS, announcements.filter(({marketsensitive}) => !marketsensitive))
      let dataSet = ([...marketDataSet, ...sensitiveDataSet])
      dataSet = dataSet.sort((a, b) => {
        const date1 = new Date(a.date)
        const date2 = new Date(b.date)
        if (date1 > date2) {
          return -1
        } else if (date1 < date2) {
          return 1
        }
        return 0
      })
      setAnnouncementData(dataSet)
    }
  }

  const fetchSecfilingData = useCallback(async (cik, startDate, endDate) => {
    const res = await getPlatformRequest(
      'sec filing',
      `documentList/${cik}?startDate=${formatDateAsYMD(startDate)}&endDate=${formatDateAsYMD(endDate)}&limit=${secFilingDataLimit}&offset=0`
    )

    if (res.data.data.items.length) {
      const secfilingData = res.data.data.items.map(item => ({
        date: formatDateAsYMD(new Date(item.acceptanceDate)),
        ...item
      }))

      const dataSet = transeformData(EVENT_TYPES.SEC_FILINGS, secfilingData)
      setSecFilingData(dataSet)
    }
  }, [])

  useEffect(() => {
    if (xrefInfo && selectedEvents[EVENT_TYPES.SEC_FILINGS] && (xrefInfo.exchange.id === exchanges.NSQ || xrefInfo.exchange.id === exchanges.NYQ) && xrefInfo.supplementalData) {
      const cik = getCik(xrefInfo)
      if (cik) {
        (async () => {
          await fetchSecfilingData(cik, getStartDate(selectedTimeFrame.days), getToDate())
          setSelectedEvents({ ...selectedEvents, [EVENT_TYPES.SEC_FILINGS]: true })
        })()
      }
    } else if (xrefInfo && selectedEvents[EVENT_TYPES.ANNOUNCEMENTS]) {
      (async () => {
        await fetchAnnouncementsData(xrefInfo.symbol, xrefInfo.exchange.code, getStartDate(selectedTimeFrame.days), selectedTimeFrame.days)
        setSelectedEvents({ ...selectedEvents, [EVENT_TYPES.ANNOUNCEMENTS]: true })
      })()
    }
  }, [xrefInfo, selectedTimeFrame])

  useEffect(() => {
    if (!(chart && chart.panels && chart.panels.length)) return
    AddEvents()
  }, [selectedEvents])

  const AddEvents = () => {
    const mainPanel = chart.panels[0]
    if (mainPanel.events && mainPanel.events.length) {
      if (chart.panels[0].events.find(e => e.params.id === EVENT_TYPES.DIVIDENDS)) {
        mainPanel.removeEvent(EVENT_TYPES.DIVIDENDS)
      }
      if (chart.panels[0].events.find(e => e.params.id === EVENT_TYPES.SPLITS)) {
        mainPanel.removeEvent(EVENT_TYPES.SPLITS)
      }
      if (chart.panels[0].events.find(e => e.params.id === EVENT_TYPES.CUSTOM)) {
        mainPanel.removeEvent(EVENT_TYPES.CUSTOM)
      }
    }

    if (selectedEvents[EVENT_TYPES.DIVIDENDS]) {
      mainPanel.addEvent(EVENT_TYPES.DIVIDENDS)
    }

    if (selectedEvents[EVENT_TYPES.SPLITS]) {
      mainPanel.addEvent(EVENT_TYPES.SPLITS)
    }

    if (selectedEvents[EVENT_TYPES.SEC_FILINGS] && secFilingData) {
      mainPanel.addEvent(EVENT_TYPES.CUSTOM, {
        name: EVENT_TYPES.SEC_FILINGS,
        dataset: secFilingData
      })
    }

    if (selectedEvents[EVENT_TYPES.ANNOUNCEMENTS] && announcementData) {
      mainPanel.addEvent(EVENT_TYPES.CUSTOM, {
        name: EVENT_TYPES.ANNOUNCEMENTS,
        dataset: announcementData
      })
    }

    adjustYAsixPadding()

    chart.loadData()
  }

  const adjustYAsixPadding = () => {
    let yAxisPadding = chart.panels[0].params.style.yAxis.padding

    if (selectedEvents[EVENT_TYPES.ANNOUNCEMENTS] || selectedEvents[EVENT_TYPES.SEC_FILINGS]) {
      yAxisPadding.top = 100
    } else if (selectedEvents[EVENT_TYPES.DIVIDENDS] || selectedEvents[EVENT_TYPES.SPLITS]) {
      yAxisPadding.top = 40
    } else {
      yAxisPadding.top = 30
    }
  }

  const registerChartEvents = () => {
    chart.eventEmitter.on('PANEL_LEGEND_UPDATE', panelLegendUpdate)
    chart.eventEmitter.on('EVENT_RENDER', eventRenderDefault)
    chart.eventEmitter.on('EVENT_REMOVE', eventRemoveDefault)
    chart.eventEmitter.on('CROSSHAIR_MOVE', addIndicatorDataToLegend)
    chart.eventEmitter.on('DATA_LOAD_STOP', onLoadEnd)
  }

  const unregisterChartEvents = () => {
    chart.eventEmitter.off('PANEL_LEGEND_UPDATE', panelLegendUpdate)
    chart.eventEmitter.off('EVENT_RENDER', eventRenderDefault)
    chart.eventEmitter.off('EVENT_REMOVE', eventRemoveDefault)
    chart.eventEmitter.off('CROSSHAIR_MOVE', addIndicatorDataToLegend)
    chart.eventEmitter.off('DATA_LOAD_STOP', onLoadEnd)
  }

  const onLoadEnd = (arg) => {
    forceRerender()
  }

  const eventRenderDefault = (params) => {
    const coords = params.coords
    const eventData = []
    for (let elem of coords) {
      eventData.push(elem)
    }

    if (params.id !== EVENT_TYPES.CUSTOM) {
      eventsData[params.id] = eventData
    } else {
      eventsData[params.name] = eventData
    }

    setEventsData({ ...eventsData })
  }

  const eventRemoveDefault = (params) => {
    if (params.eventType) {
      if (params.eventType === EVENT_TYPES.CUSTOM) {
        eventsData[EVENT_TYPES.SEC_FILINGS] = []
        eventsData[EVENT_TYPES.ANNOUNCEMENTS] = []
      } else eventsData[params.eventType] = []
      setEventsData({ ...eventsData })
    }
  }

  const togglePanelEvent = (event) => {
    selectedEvents[event] = !selectedEvents[event]
    setSelectedEvents({ ...selectedEvents })
  }

  const panelLegendUpdate = (arg) => {
    const documentObj = isWebComponent ? document.querySelector('price-chart-webcomponent').shadowRoot : document
    let containerDiv = documentObj.querySelector(`div.priceChartLegend`)
    if (!containerDiv) {
      containerDiv = document.createElement('div')
      containerDiv.setAttribute('class', 'priceChartLegend')
      arg.legendElement.appendChild(containerDiv)
    }

    let leftDiv = documentObj.querySelector(`div.priceChartLegend>div.left-section`)
    if (!leftDiv) {
      leftDiv = document.createElement('div')
      leftDiv.setAttribute('class', 'left-section')
      containerDiv.appendChild(leftDiv)
    }

    let rightDiv = documentObj.querySelector(`div.priceChartLegend>div.right-section`)
    if (!rightDiv) {
      rightDiv = document.createElement('div')
      rightDiv.setAttribute('class', 'right-section')
      containerDiv.appendChild(rightDiv)
    }

    if (arg.panel === chart.panels[0]) {
      leftDiv.innerHTML = ''
      createLegend(leftDiv)
    }
  }

  const createLegend = (leftDiv) => {
    let symbolDiv = document.createElement('div')
    symbolDiv.setAttribute('class', 'symbol')
    leftDiv.appendChild(symbolDiv)

    symbolDiv.appendChild(document.createElement('div')).setAttribute('class', 'swatch')

    let info = document.createElement('div')
    info.setAttribute('class', 'info')
    info.innerHTML = `${xrefInfo.symbol}:${xrefInfo.exchange.name}`
    symbolDiv.appendChild(info)

    let customEventSymbols = document.createElement('div')
    customEventSymbols.setAttribute('class', 'event-icons-wrapper')
    leftDiv.appendChild(customEventSymbols)

    if (xrefInfo.exchange.id === exchanges.NSQ || xrefInfo.exchange.id === exchanges.NYQ) {
      let secFiling = document.createElement('div')
      secFiling.setAttribute('class', 'event-icon')
      customEventSymbols.appendChild(secFiling)

      secFiling.appendChild(document.createElement('div')).setAttribute('class', 'other-icon')

      let secFilingTypeName = document.createElement('div')
      secFilingTypeName.setAttribute('class', 'name')
      secFilingTypeName.innerHTML = 'SEC Filings'
      secFiling.appendChild(secFilingTypeName)
    } else {
      let marketsensitive = document.createElement('div')
      marketsensitive.setAttribute('class', 'event-icon')
      customEventSymbols.appendChild(marketsensitive)

      marketsensitive.appendChild(document.createElement('div')).setAttribute('class', 'sensitive-icon')

      let marketsensitiveName = document.createElement('div')
      marketsensitiveName.setAttribute('class', 'name')
      marketsensitiveName.innerHTML = 'Market Sensitive'
      marketsensitive.appendChild(marketsensitiveName)

      let announcement = document.createElement('div')
      announcement.setAttribute('class', 'event-icon')
      customEventSymbols.appendChild(announcement)

      announcement.appendChild(document.createElement('div')).setAttribute('class', 'other-icon')

      let announcementName = document.createElement('div')
      announcementName.setAttribute('class', 'name')
      announcementName.innerHTML = 'Other Announcement'
      announcement.appendChild(announcementName)
    }
  }

  const addIndicatorDataToLegend = (arg) => {
    const documentObj = isWebComponent ? document.querySelector('price-chart-webcomponent').shadowRoot : document
    let rightDiv = documentObj.querySelector(`div.priceChartLegend>div.right-section`)
    if (rightDiv) {
      rightDiv.innerHTML = ''

      let prefix = ''
      let suffix = ''
      const currency = arg.currency || ''
      // currency
      if (/USD|AUD/.test(currency)) {
        prefix = '$'
      } else if (currency === 'GBP') {
        prefix = '&pound;'
      }

      const crossHairData = {
        O: prefix + arg.open + suffix,
        H: prefix + arg.high + suffix,
        L: prefix + arg.low + suffix,
        C: prefix + arg.close + suffix
      }

      crossHairData.timestampLabel = formatDateAsDMY(arg.date)

      let closeDiv = document.createElement('div')
      closeDiv.setAttribute('class', 'close-price')
      closeDiv.innerHTML = `Close ${crossHairData.C}`
      rightDiv.appendChild(closeDiv)

      let dateDiv = document.createElement('div')
      dateDiv.setAttribute('class', 'close-date')
      dateDiv.innerHTML = `${crossHairData.timestampLabel}`
      rightDiv.appendChild(dateDiv)
    }
  }

  return (
    <div {...priceChart()}>
      <div {...priceChart('wrapper')}>
        <header {...priceChart('header')}>
          <h3>Price chart</h3>
        </header>
        <div {...priceChart('tool-bar')}>
          <div {...priceChart('tool-bar__left')}>
            <EventMenu
              exchangeID={exchangeID}
              selectedEvents={selectedEvents}
              onEventToggle={togglePanelEvent}
            />
          </div>
          <div {...priceChart('tool-bar__right')}>
            <ul>
              <li className='menu menu--timeframe'>
                <TimeFrame chart={chart} currentTimeFrame={selectedTimeFrame} onTimeFrameSelected={onTimeFrameSelected} handleFrequency={handleFrequency} />
              </li>
              <li className='menu'>
                <Frequency chart={chart} handleFrequency={handleFrequency} currentDays={selectedTimeFrame.days} selectedFrequency={selectedFrequency} />
              </li>
              <li className='menu markertype'>
                <Markertype chart={chart} />
              </li>
            </ul>
          </div>
        </div>
        <div {...priceChart('modcharts_container')}>
          <div className='wrapper'>
            <div id='chart-dom-element' style={{ height: 347, width: '100%', margin: 'auto' }} data-testid='custom-chart' />
            {eventsData && Object.keys(eventsData).length > 0 && (
              <EventsOverlay eventsData={eventsData} token={token} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PriceChart.propTypes = {
  token: PropTypes.string,
  symbol: PropTypes.string,
  isWebComponent: PropTypes.bool,
  exchange: PropTypes.string
}

export default PriceChart
