export const exchanges = {
  'ASX': 110,
  'NAQ': 791,
  'NMQ': 823,
  'NSQ': 840, // NASDAQ
  'NYQ': 855, // NYSE
  'PCQ': 897,
  'SFE': 1009,
  'ICQ': 1225,
  'BTQ': 1452,
  'CHA': 1459
}

export const EVENT_TYPES = {
  EARNINGS: 'earnings',
  DIVIDENDS: 'dividends',
  SPLITS: 'splits',
  ANNOUNCEMENTS: 'announcementscustom',
  SEC_FILINGS: 'secfilingscustom',
  CUSTOM: 'custom'
}

export const frequencyMenuOptions = [
  { id: '1', interval: '1', period: 'Minute', intraday: 1, labelId: '1 Minute' },
  { id: '2', interval: '3', period: 'Minute', intraday: 1, labelId: '3 Minute' },
  { id: '3', interval: '5', period: 'Minute', intraday: 1, labelId: '5 Minute' },
  { id: '4', interval: '15', period: 'Minute', intraday: 1, labelId: '15 Minute' },
  { id: '5', interval: '1', period: 'Hour', intraday: 1, labelId: '1 Hour' },
  { id: '6', interval: '1', period: 'Day', labelId: 'Daily' },
  { id: '7', interval: '1', period: 'Week', labelId: 'Weekly' },
  { id: '8', interval: '1', period: 'Month', labelId: 'Monthly' }
]

export const getValidFreq = (currentDays, row) => {
  return !(currentDays <= 91 && row.period === 'Month') &&
    ((currentDays <= 21 && row.intraday) ||
      (currentDays > 21 && !row.intraday))
}

export const markerMenuOptions = [
  { id: 'fill', labelId: 'Area' },
  { id: 'ohlc', labelId: 'OHLC' },
  { id: 'hlc', labelId: 'HLC' },
  { id: 'candlestick', labelId: 'Candlestick' },
  { id: 'line', labelId: 'Line' },
  { id: 'bar', labelId: 'Bar' },
  { id: 'shape', labelId: 'Dot' }
]

export const timeframeMenuOptions = [
  { id: '1', labelId: '1 Month', days: 31 },
  { id: '2', labelId: '3 Month', days: 91 },
  { id: '3', labelId: '1 Year', days: 365 },
  { id: '4', labelId: '2 Year', days: 365 * 2 }
]
