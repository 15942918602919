/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Menu from './Menu.js'
import { timeframeMenuOptions, frequencyMenuOptions, getValidFreq } from '../consts'

const timeframeOptions = timeframeMenuOptions.map(menu => ({
  label: menu.labelId,
  value: menu.id
}))

const TimeFrame = ({chart, currentTimeFrame, onTimeFrameSelected, handleFrequency}) => {
  TimeFrame.propTypes = {
    chart: PropTypes.object,
    currentTimeFrame: PropTypes.object,
    onTimeFrameSelected: PropTypes.func,
    handleFrequency: PropTypes.func
  }

  const [showFreqPopUp, setShowFreqPopUp] = useState(false)
  const [pos, setPos] = useState({})

  const [freqToShow, setFreqToShow] = useState([])

  const onTimeframeChange = (id) => {
    const timeFrameSelected = timeframeMenuOptions.find(option => option.id === id)
    onTimeFrameSelected(timeFrameSelected)
  }

  const onMenuItemMouseEnter = (e, item) => {
    const timeFrame = timeframeMenuOptions.find(option => option.id === item.value)
    const freqOptions = frequencyMenuOptions.filter(row => getValidFreq(timeFrame.days, row)).map(row => ({
      label: row.labelId,
      value: row.id,
      days: timeFrame.days
    }))

    setFreqToShow(freqOptions)

    const index = parseInt(e.currentTarget.getAttribute('data-index'))
    pos.X = 100
    pos.Y = index === 0 ? 0 : index * 26

    setPos({...pos})
    setShowFreqPopUp(true)
  }

  const onFreqClick = (freq) => {
    const frequency = frequencyMenuOptions.find(option => option.id === freq.value)
    handleFrequency(frequency, freq.days)
    setShowFreqPopUp(false)
  }

  const getLabel = () => {
    const isZoomed = chart ? !!chart.params.dateStart : false
    if (isZoomed) {
      return 'Custom'
    }

    return currentTimeFrame.labelId
  }

  return (
    <React.Fragment>
      <Menu btnClassName='button' options={timeframeOptions} onItemClick={onTimeframeChange} onMenuItemMouseEnter={onMenuItemMouseEnter} onClose={() => setShowFreqPopUp(false)}>
        <div className='header'>
          <div className='icon--timeframe' />
          {getLabel()}
        </div>
      </Menu>
      {
        showFreqPopUp && (
          <div className='frequency-popover' style={{left: `${pos.X}px`, top: `${pos.Y}px`}} onMouseEnter={() => setShowFreqPopUp(true)} onMouseLeave={() => setShowFreqPopUp(false)}>
            <div className='header'>
              <div className='icon--frequency' />
              Frequency
            </div>
            <ul className='items-wrapper'>
              {
                freqToShow && freqToShow.map(freq => (
                  <li key={freq.value} onClick={() => onFreqClick(freq)} className='menu-item'>{freq.label}</li>
                ))
              }
            </ul>
          </div>
        )
      }

    </React.Fragment>
  )
}

export default TimeFrame
