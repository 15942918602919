import axios from 'axios'
import moment from 'moment-timezone'

export const transeformData = (eventType, eventData) => {
  if (!eventData || eventData.lenth === 0) {
    return []
  }

  const map = new Map()
  eventData.forEach(obj => {
    if (map.has(obj.date)) {
      map.get(obj.date).push(obj)
    } else {
      map.set(obj.date, [obj])
    }
  })

  const event = {
    type: eventType
  }

  const arrayData = Array.from(map).map(([date, eventData]) => ({date, customData: eventData}))
  arrayData.forEach(element => {
    Object.assign(element, event)
  })
  return arrayData
}

export const formatDateAsYMD = (date) => {
  const getYear = date.toLocaleString('default', { year: 'numeric' })
  const getMonth = date.toLocaleString('default', { month: '2-digit' })
  const getDay = date.toLocaleString('default', { day: '2-digit' })
  return getYear + '-' + getMonth + '-' + getDay
}

export const formatDateAsDMY = (date) => {
  const getYear = date.toLocaleString('default', { year: 'numeric' })
  const getMonth = date.toLocaleString('default', { month: 'numeric' })
  const getDay = date.toLocaleString('default', { day: 'numeric' })
  return getDay + '/' + getMonth + '/' + getYear
}

// Opening any type document in a new tab
export const createDocLink = (feedId, documentTag, versionTag, docType, token) => {
  let acceptHeader =
    docType === 'HTM' ? 'text/html' : `application/${docType.toLowerCase()}`
  axios
    .get(
      `${window.MD.CDN_API_URL}${window.MD.API_URL.replace('https://', '')}${
        window.MD.PLATFORM_SEC_BASE_URL
      }/document/${feedId}-${documentTag}?versionTag=${versionTag}`,
      {
        responseType: 'blob',
        headers: {
          Accept: acceptHeader,
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((response) => {
      window.open(URL.createObjectURL(response.data), '_blank')
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getFrequencyByDays = days => {
  let interval = 0
  let period = ''

  // dynamic interval/period
  if (days < 3) {
    interval = 3
    period = 'Minute'
  } else if (days <= 5) {
    interval = 5
    period = 'Minute'
  } else if (days <= 10) {
    interval = 15
    period = 'Minute'
  } else if (days <= 21) {
    interval = 1
    period = 'Hour'
  } else if (days <= 365 * 1) {
    interval = 1
    period = 'Day'
  } else if (days < 365 * 10) {
    interval = 1
    period = 'Week'
  } else {
    interval = 1
    period = 'Month'
  }

  return {
    dataInterval: interval,
    dataPeriod: period
  }
}

export const secFilingDateFormat = (iso) => {
  return moment.tz(iso, 'America/New_York').format('Do MMMM YYYY, h:mm a z')
}

export const createAnnouncementLink = (documentKey, accessToken) => {
  return `${window.MD.COMSEC_CDN_API_URL}${window.MD.COMSEC_BASE_URL}/event/document/${documentKey}/pdf?access_token=${accessToken}`
}

export const announcementDateFormat = (iso) => {
  return moment.tz(iso, 'Australia/Sydney').format('Do MMMM YYYY, h:mm a z')
}
