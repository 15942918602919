import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Menu from './Menu.js'
import { markerMenuOptions } from '../consts'

const marketMenuItem = (menu) => (
  <div className='markertype__menu-item'>
    <div className={`icon--${menu.id}`} />
    {menu.labelId}
  </div>
)

const markerOptons = markerMenuOptions.map(menu => ({
  label: marketMenuItem(menu),
  value: menu.id
}))

const Markertype = ({chart}) => {
  Markertype.propTypes = {
    chart: PropTypes.object
  }

  const [selectedMarker, setSelectedMarker] = useState(
    markerMenuOptions[0]
  )

  useEffect(() => {
    if (!chart) return
    const indicator = chart.getPrimaryIndicator()
    if (indicator) {
      indicator.params.markerType = selectedMarker.id
      chart.render()
    }
  }, [selectedMarker])

  const onMarkerChange = (id) => {
    const markerSelected = markerMenuOptions.find(option => option.id === id)
    setSelectedMarker(markerSelected)
  }

  return (
    <Menu btnClassName='button' options={markerOptons} onItemClick={onMarkerChange}>
      <div className='header'>
        <div className={`icon--${selectedMarker.id}`} />
        {selectedMarker.labelId}
      </div>
    </Menu>
  )
}

export default Markertype
